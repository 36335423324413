/* You can add global styles to this file, and also import other style files */

// @import 'variables';
// @import '../node_modules/font-awesome/scss/font-awesome';
// @import "@angular/material/prebuilt-themes/indigo-pink.css";
@import 'theme.scss';
@import "/src/app/breakpoints";

@tailwind base;
@tailwind components;
@tailwind utilities;

.attach-dialog {
  .mat-dialog-container {
    padding: 0;
    min-height: 293px !important;
  }

  .mat-dialog-title {
    margin: 0 !important;
    font-weight: 100 !important;
  }
}

.backdropUserMenu {
  opacity: 0; // background: rgba(0, 0, 0, 0);
}

.dialogUserMenu {
  .mat-dialog-container {
    padding: 0;
    // min-height: 293px !important;
  }

  .mat-dialog-title {
    margin: 0 !important;
    font-weight: 100 !important;
  }
}

.fab-add {
  z-index: 5;
  position: fixed;
  display: flex;
  right: 35px;
  bottom: 20px;
}

body {
  font-family: 'Open Sans', Roboto, Arial, sans-serif;
  margin: 0;
}

h3.table-header {
  text-align: center;
  text-transform: uppercase;
  margin-top: 0;
}

.disable-ctrl {
  pointer-events: none !important;
}

//! Test Selection components - START
.mat-expansion-panel {
  background: #243e55;
}

.p-listbox.listbox {
  background: #243e55;//#1c3041;

  .p-listbox-item {
    color: white;
    background: #1c3041;

    // border in all but last item
    &:not(:last-child) {
      border-bottom: 1px solid #526a7e;
    }
  }

  .p-listbox-item.p-highlight {
    color: white;
    background: #3593ff;
  }

  .p-listbox-item:not(.p-disabled):hover {
    background: #3593ff !important;
    color: white !important;
  }

  ul {
    line-height: normal;
    li {
      min-height: 60px;
    }
  }

  /** desktop **/
  @include breakpoint($large-handset) {
    ul {
      line-height: unset;
    }
  }

}

.p-listbox.listbox-chips {
  @extend.listbox;

  /** mobile **/
  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-height: 200px;
    overflow: auto;
    grid-auto-flow: row dense;
    gap: 5px;
    padding: 0.2rem;
  }
  .p-listbox-item {
    border: 1px solid #526a7e;
  }

  /** desktop **/
  @include breakpoint($large-handset) {
    ul{
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;
      padding: 0.5rem;
      max-height: unset;
    }
  }
}

.mat-expansion-panel-header{
  height: auto;
  min-height: 48px;
}


.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  flex-basis: 0;
  color: white;
}

.mat-expansion-panel-header-title {
  font-weight: 700;
}

.mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

//! Test Selection components - END
.alertButtonNo, .alertButtonOK  {
  color: #fff!important;
}

.mat-form-field-label{
  color: #fff!important;
}
